<template>
  <div class="report">
    <div class="events">
      <div v-for="event in events" :key="event.title" class="event-box">
        <v-icon :icon="event.icon" color="primary" class="mr-4" />
        <div class="font-subtitle">{{ $t(event.title) }}</div>
      </div>
    </div>
    <div v-if="url" class="video">
      <div style="padding:56.25% 0 0 0;position:relative;"><iframe :src="url" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props:{
    url:{
      type: String,
      default: 'https://player.vimeo.com/video/908149324?h=bad75c560a' // 前台寫死
    }
  },
  data() {
    return {
      events: [
        { title: Words.HomeReportCaption1, icon: 'hotel_class' },
        { title: Words.HomeReportCaption2, icon: 'hotel_class' },
        { title: Words.HomeReportCaption3, icon: 'hotel_class' },
        { title: Words.HomeReportCaption4, icon: 'hotel_class' },
      ]
    }
  },
})
</script>

<style lang="stylus" scoped>
.report
  +over(lg)
    display flex
    align-items center
    gap 24px
  .events
    +over(lg)
      width 40%
      padding 0 24px
  .event-box
    display flex
    align-items center
    padding 16px 0
    +over(lg)
      min-height 90px
    + .event-box
      border-top 1px solid $black-0-12
  .video
    flex-grow 1
    +under(lg)
      max-width 576px
      margin 24px auto 0
</style>